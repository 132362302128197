require('../../../resources/js/bootstrap');

import lottie from 'lottie-web';

window.initQuestion = function(questionType) {

    $(".answerblock").bind("click", function () {
        $('#button_confirm').fadeIn(500);
        var obj_id = $(this).attr("id").replace("answer_", "");
        var data = $(this).attr("data");
        if (data == 0) {
            if(questionType == 'radio')
            {
                $('.optionClass').prop('checked',false);
                $('.answerblock_selected').hide();
                $('.answerblock').attr("data", 0);
            }
            $(this).attr("data", 1);
            $('#answer_selected_' + obj_id).show();
            $('#option_' + obj_id).prop('checked',true);
        } else {
            $(this).attr("data", 0);
            $('#answer_selected_' + obj_id).hide();
            $('#option_' + obj_id).prop('checked',false);
        }
    });

    var audioPlayer = document.getElementById("audioPlayer");
    //audioPlayer.play();

    quizTimer();
}

function quizTimer() {
    var timer = 49;
    setInterval(function () {
        var seconds = parseInt(timer % 60, 10);
        seconds = seconds < 10 ? "0" + seconds : seconds;
        $('#counter').html(seconds+"&nbsp;");
        if (--timer < 0) {
            $('#questionform').submit();
        }
    }, 1000);
}

window.playScore = function(score,maxscore)
{
    var typeAnimatie="";
    if(score == maxscore ) typeAnimatie = "juist";
    if(score < maxscore ) typeAnimatie = "bijna";
    if(score == 0 ) typeAnimatie = "fout";

    var anim1;
    var elem1 = document.getElementById('animation1');
    var animData1 = {
        container: elem1,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        rendererSettings: {
            progressiveLoad: true
        },
        path: '../json/'+typeAnimatie+'.json'
    };

    var anim2;
    var elem2 = document.getElementById('animation2');
    var animData2 = {
        container: elem2,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        rendererSettings: {
            progressiveLoad: true
        },
        path: '../json/'+score+'.json'
    };

    $('#animation1').show();
    $('#animation2').show();
    anim1 = lottie.loadAnimation(animData1);
    anim1.setSpeed(1);
    anim1.addEventListener('complete', removeAnim);
    anim2 = lottie.loadAnimation(animData2);
    anim2.setSpeed(1);
}

function removeAnim() {
    $('#animation1').hide();
    $('#animation2').hide();
    $('.answerblock_correct').show();
    $('.answerblock_false').show();
    var mwidth = 0;
    $( '.answertext' ).each(function( index ) {
        var width = $( this ).width();
        if(width > mwidth ) mwidth = width;
    });
    var totwidth = $('#normaalverdeling').width();
    var newwidth = ((mwidth/totwidth)*100)+1;
    $( '.answer_bench_holder' ).each(function( index ) {
        $( this ).css("left",newwidth+"vw");
    });
    var total = 100.0;
    var counter = 0;
    $( '.answer_bench' ).each(function( index ) {
        total = parseFloat(total) - parseFloat($( this ).attr("data"));
        counter++;
    });
    var teller = 0;
    $( '.answer_bench' ).each(function( index ) {
        var ran = $( this ).attr("data");
        teller++;
        if((counter==teller)&&($('#question_type').val()=='radio')) ran = parseFloat(ran) + parseFloat(round(total,1));
        $( this ).animate({
            width:ran+"%"
            }, 1000, function() {
        });
        $( this ).html(round(ran,1).toFixed(1)+"%");
    });
    $('.answer_bench_holder').show();
    $('.answer_bench').show();
    //var width = $( '#normaalverdeling_overlay').width();
    /*
    var ran = 30+Math.floor(Math.random() * 40);
    $( "#normaalverdeling_overlay" ).animate({
        width: ran+"%"
    }, 1000, function() {
        // Animation complete.
    });

     */
}

function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

$(document).ready(function () {
    $('[data-toggle=modal][data-open]').click();
});
